/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                window.cookieconsent.initialise({
                    container: document.getElementById("content"),
                    palette: {
                        popup: {background: "#1d2c50"},
                        button: {background: "#0b9ecf"},
                    },
                    content: {
                        message: "We use cookies to help improve your experience of our website. To consent to this simply continue using the site or click the button."
                    },
                    revokable: false,
                    onStatusChange: function (status) {
                        console.log(this.hasConsented() ?
                            'enable cookies' : 'disable cookies');
                    },
                    law: {
                        regionalLaw: false,
                    },
                    location: false,
                    showLink: false,
                    position: "bottom-right",
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Resize the sticky footer
                /*var bumpIt = function() {
                        $('body').css('margin-bottom', $('.footer').height());
                    },
                    didResize = false;

                bumpIt();

                $(window).resize(function() {
                    didResize = true;
                });
                setInterval(function() {
                    if(didResize) {
                        didResize = false;
                        bumpIt();
                    }
                }, 250);*/

            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Software landing page
        'software': {
            init: function () {
                $(function () {
                    $('.card-software-product').matchHeight();
                });
            }
        },
        // Software training landing page
        'training': {
            init: function () {
                $(function () {
                    $('.card-software-product').matchHeight();
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_template_contact_php': {
            init: function () {
                // JavaScript to be fired on contact pages
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    //$(document).tooltip({selector: '[data-toggle="tooltip"]'});
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
    $(document).popover({selector: '[data-toggle="popover"]', trigger: 'hover', container: 'body'});


})(jQuery); // Fully reference jQuery after this point.
